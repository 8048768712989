<!--SelectLabelDialog:标签选择弹窗 2024/07/28 10:51:02 huangmx-->

<template>
  <div class="select-label-dialog">
    <el-dialog :title="$t('selectLabel')" :visible.sync="localVisible" @close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" width="712px">
      <div style="min-height: 200px;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="item in classifications" :key="item.id" :label="item.name" :name="item.name">
            <div class="flex-tag">
              <el-tag :key="label.id" v-for="label in item.labels" @click="clickTag(label)">
                {{ label.name }}
              </el-tag>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="selected-btns" v-if="selectedLabels.length > 0">
        <p>{{ $t('selectedLabel') }}</p>
        <div class="flex-tag">
          <el-tag :key="tag.id" v-for="tag in selectedLabels" closable :disable-transitions="false"
            @close="delTag(tag)">
            {{ tag.name }}
          </el-tag>
        </div>

      </div>
      <div class="bottom-btn">
        <el-button type="primary" size="mini" @click="ok">{{ $t('confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { labelApi, classificationApi } from '@/utils/api'
export default {
  name: 'SelectLabelDialog',
  props: {
    visible: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      localVisible: false,
      classifications: [],
      activeName: '',
      selectedLabels: []
    }
  },
  watch: {
    visible: function (newValue) {
      this.localVisible = newValue
    }
  },
  created () {
    this.localVisible = this.visible
  },
  mounted () {
    this.getClassifications()
  },
  methods: {
    handleClose: function () {
      this.localVisible = false
      this.$emit('update:visible', false)
    },
    getClassifications: function () {
      if (this.$store.state.classifications.length > 0) {
        this.classifications = this.$store.state.classifications
        this.activeName = this.classifications[0].name
        return
      }
      classificationApi.getClassificationList().then(resp => {
        if (resp.httpStatus === 200) {
          console.log('get classifications', resp)
          labelApi.getLabelList().then(resp2 => {
            if (resp2.httpStatus === 200) {
              console.log('get labels', resp2)
              resp.data.forEach(item => {
                item.labels = resp2.data.filter(item2 => item2.classificationId === item.id)
              })
              this.classifications = resp.data
              this.activeName = this.classifications[0].name
              this.$store.commit('setClassifications', this.classifications)
            }
          })
        }
      })
    },
    handleClick: function (tag) {
      this.activeName = tag.name
    },
    clickTag: function (tag) {
      if (this.selectedLabels.find(item => item.id === tag.id)) {
        return
      }
      // 最多只能选择 5 个
      if (this.selectedLabels.length >= 5) {
        this.$message.error(this.$t('max5Labels'))
        return
      }
      this.selectedLabels.push(tag)
    },
    delTag: function (tag) {
      this.selectedLabels = this.selectedLabels.filter(item => item.id !== tag.id)
    },
    ok: function () {
      if (this.selectedLabels.length === 0) {
        this.$message.error(this.$t('less1Labels'))
        return
      }
      this.localVisible = false
      this.$emit('ok', this.selectedLabels)
    }
  }
}
</script>

<style scoped>
.flex-tag {
  display: flex;
  flex-wrap: wrap;
}

.flex-tag span {
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.bottom-btn {
  display: flex;
  justify-content: right;
  margin-bottom: 15px
}

.selected-btns p {
  text-align: left;
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  margin-bottom: 20px;
  margin-top: 10px;
}

.select-label-dialog>>>.el-dialog__wrapper .el-dialog {
  border-radius: 14px;
  padding: 5px;
}
</style>
