<!--index:Hi, index 2024/07/03 22:52:20 YuAng-->

<template>
  <div class="community-index page-center-content" v-infinite-scroll="getArtiles" :infinite-scroll-immediate=false
    :infinite-scroll-distance=30 :infinite-scroll-disabled="!loadMore">
    <feed-list-common v-for="item in articleList" :key="'post' + item.id" :item="item"></feed-list-common>

    <publish-post-btn></publish-post-btn>
  </div>
</template>
<script>
import FeedListCommon from '../components/FeedListCommon.vue'
import PublishPostBtn from '../components/PublishPostBtn.vue'
import { articleApi } from '@/utils/api'

export default {
  components: { FeedListCommon, PublishPostBtn },
  name: 'index',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      isloading: false,
      currentPage: 1,
      loadMore: true,
      articleList: []
    }
  },
  created () {
    this.getArtiles()
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    /**
     * 文章列表
     */
    getArtiles: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      articleApi.getArticleListByClassificationId({
        articleType: 'POST',
        pageSize: 20,
        currentPage: this.currentPage
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200) {
          this.currentPage++
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          if (!resp.data || resp.data.length === 0) {
            return
          }
          this.articleList = this.articleList.concat(resp.data)
          console.log('article list', this.articleList)
          const articleIds = this.articleList.map(item => item.id)
          this.getArticleInteractions(articleIds.join(','))
        }
      }).catch(err => {
        console.log('getArticleListByClassificationId error', err)
        this.isloading = false
      })
    },
    /**
     *  文章互动数据
     * @param {文章 id} articleIds 用逗号分割
     */
    getArticleInteractions: function (articleIds) {
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200) {
          this.currentPage++
          // 为 articles 追加 互动数据
          const interactionMap = new Map(resp.data.map(interaction => [interaction.articleId, interaction]))
          this.articleList = this.articleList.map(article => {
            const interactionData = interactionMap.get(article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              return {
                ...article,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            } else {
              // 如果没有找到互动数据，则保留原有文章数据
              return article
            }
          })
          console.log('article interactions', this.articleList)
        }
      })
    }
  }
}
</script>

<style scoped>
.community-index {
  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 1300px;
  overflow-y: auto;
}
</style>
